import React, { useState, Component } from 'react';
import { withTranslation } from 'react-i18next';

class ToggleSwitch extends Component {

    constructor(props) {
        super(props);
        // Ensure options and value are defined before using them
        const isChecked = props.options && props.value && props.value.toLowerCase() === props.options[1].toLowerCase();
        this.state = {
            isChecked: isChecked,
        };
        this.handleToggle = this.handleToggle.bind(this);
    }

    componentDidMount() {
        if (this.props.value) {
            this.updateCheckedState(this.props.value);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.updateCheckedState(this.props.value);
        }
    }

    updateCheckedState(value) {
        // Additional safeguard in case value or options are undefined
        if (value && this.props.options) {
            const isChecked = value.toLowerCase() === this.props.options[1].toLowerCase();
            this.setState({ isChecked });
        }
    }

    handleToggle() {
        // Using options safely with a fallback to prevent errors
        const options = this.props.options || [];
        const newValue = !this.state.isChecked ? options[1] : options[0];

        this.setState(state => ({ isChecked: !state.isChecked }));
        // Ensure onChange and newValue are defined before calling toLowerCase
        if (newValue && this.props.onChange) {
            this.props.onChange(newValue.toLowerCase());
        }
    }
        
    render() {
        const options = this.props.options || ['Option 1', 'Option 2'];
        const { isChecked } = this.state;
        const toggleId = `toggle-${options[0]}-${options[1]}`.toLowerCase().replace(/\s+/g, '-');
        const { t } = this.props

        return (
            <div className="custom-toggle flex items-center justify-between">
                <label htmlFor={toggleId} className="flex items-center cursor-pointer">
                    <div className={`ml-3 mr-4 font-medium label-text ${isChecked ? null : 'active'}`}>{t(options[0])}</div>
                    <div className="relative">
                    <input
                        type="checkbox"
                        id={toggleId}
                        className="sr-only"
                        checked={isChecked}
                        onChange={this.handleToggle}
                    />
                    <div className={`block bg-transparent-edges w-14 h-8 rounded-full transition-colors duration-300 ease-in-out`}></div>
                    <div className={`dot absolute left-1 top-1 bg-yellow w-6 h-6 rounded-full transition-transform duration-300 ease-in-out ${isChecked ? 'translate-x-6' : 'translate-x-0'}`}
                    ></div>
                    </div>
                    <div className={`ml-4 mr-3 font-medium label-text ${isChecked ? 'active' : null }`}>{t(options[1])}</div> 
                </label>
            </div>
        );
    }
}

class IsSoldSwitch extends ToggleSwitch {

    render() {
        const options = this.props.options || ['Option 1', 'Option 2'];
        const { isChecked } = this.state;
        const toggleId = `toggle-${options[0]}-${options[1]}`.toLowerCase().replace(/\s+/g, '-');
        const { t } = this.props

        return (
            <div className="custom-toggle flex items-center justify-between" style={{width: 'max-content'}}>
                <label htmlFor={toggleId} className="flex items-center cursor-pointer">
                    <div className="ml-3 mr-4 text-shaded-clay font-medium font-mabry w-100" style={{fontSize: '18px'}}>{t('Artwork sold')}</div>
                    {/* <div className="ml-3 mr-4 font-medium font-mabry w-100" style={{fontSize: '18px'}}>Artwork sold</div> */}
                    <div className="relative">
                        <input
                            type="checkbox"
                            id={toggleId}
                            className="sr-only"
                            checked={isChecked}
                            onChange={this.handleToggle}
                        />
                        {/* <div className={`block bg-transparent-edges w-14 h-8 rounded-full transition-colors duration-300 ease-in-out`}></div>
                        <div className={`dot absolute left-1 top-1 bg-yellow w-6 h-6 rounded-full transition-transform duration-300 ease-in-out ${isChecked ? 'translate-x-6' : 'translate-x-0'}`}></div> */}
                        <div className="block bg-transparent-edges w-14 h-8 rounded-full transition-colors duration-300 ease-in-out" style={{width: '60px', border: '1.5px solid rgb(255 255 255 / 50%)'}}></div>
                        <div className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ease-in-out ${isChecked ? 'translate-x-6' : 'translate-x-0'} `} style={{backgroundColor: 'rgb(158 154 145 / 50%)'}}></div>
                    </div>
                </label>
            </div>
        );
    }
}

export const TranslatedIsSoldSwitch = withTranslation()(IsSoldSwitch);
export default withTranslation()(ToggleSwitch);
