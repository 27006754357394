import React, { useEffect } from 'react';
import CustomButton from './customComponents/buttons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

function ConfirmationPage({ imageArray, onConfirm }) {
  const navigate = useNavigate();
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [navigate]);

  const refreshTab = () => {
    window.location.reload();
  };

  return (
    <div className="introduction-container upload-confirmation flex flex-col justify-center items-center h-screen text-center px-4">
      <div className="content-container space-y-4 max-w-xs mx-auto">
        <h1>{t('Congratulations you uploaded!')}</h1>
        <CustomButton className='primaryButton w-full py-2 rounded-md shadow-sm' onClick={refreshTab}>
          <span>{t('Upload Again')}</span>
        </CustomButton>
      </div>
    </div>
  );
}

export default ConfirmationPage;
