import React, { createContext, useContext, useEffect, useState } from 'react';
import userService from '../services/user.service';

const VendorContext = createContext();

export const VendorProvider = ({ children }) => {
    const [vendor, setVendor] = useState(null);  // Default state can be null or another appropriate value

    useEffect(() => {
        const getVendor = async () => {
            try {
                const { data } = await userService.getVendor();
                if(data?.is_sign_up_complete) setVendor(data);
            } catch (error) {
                console.error('Failed to fetch vendor', error);
            }
        };
        getVendor();
    }, []);

    return (
        <VendorContext.Provider value={{ vendor }}>
            {children}
        </VendorContext.Provider>
    );
};

// Correctly named custom hook for using context
export const useVendor = () => useContext(VendorContext);
