import userService from '../services/user.service';

function get_file_extension(image) {
    // Replace 'image.filename' with the actual property of the image object that holds the filename
    const filename = image.filename || image.name || image.path; 
    return filename.split('.').pop();
}


export async function convertAndResizeImage(imageFile) {
  return new Promise((resolve, reject) => {
    // Create an image element
    const img = new Image();
    const objectURL = URL.createObjectURL(imageFile);
    img.src = objectURL;

    img.onload = () => {
      // Ensure we cleanup the object URL after loading
      URL.revokeObjectURL(objectURL);

      // Create a canvas element
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas size to desired dimensions
      const maxSize = 1920;
      const ratio = Math.min(maxSize / img.width, maxSize / img.height);
      canvas.width = img.width * ratio;
      canvas.height = img.height * ratio;

      // Draw the image onto the canvas and resize it
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Convert the canvas to a JPEG blob with a quality parameter (optional)
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 0.9); 
    };

    img.onerror = (error) => {
      // Cleanup if there's an error
      URL.revokeObjectURL(objectURL);
      reject(error);
    };
  });
}

export async function convertAndResizeImageWithExtension(imageFile) {
  return new Promise((resolve, reject) => {
    // Create an image element
    const img = new Image();
    const objectURL = URL.createObjectURL(imageFile);
    img.src = objectURL;

    img.onload = () => {
      // Ensure we cleanup the object URL after loading
      URL.revokeObjectURL(objectURL);

      // Create a canvas element
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas size to desired dimensions
      const maxSize = 1920;
      const ratio = Math.min(maxSize / img.width, maxSize / img.height);
      canvas.width = img.width * ratio;
      canvas.height = img.height * ratio;

      // Draw the image onto the canvas and resize it
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Convert the canvas to a JPEG blob with a quality parameter (optional)
      canvas.toBlob((blob) => {
        // Create a new File object from the blob with the desired filename
        const file = new File([blob], 'compress.jpeg', { type: 'image/jpeg' });
        // Resolve with the file object
        resolve(file);
      }, 'image/jpeg', 0.9); 
    };

    img.onerror = (error) => {
      // Cleanup if there's an error
      URL.revokeObjectURL(objectURL);
      reject(error);
    };
  });
}


export async function upload_images_to_cdn(imageArrayObjects) {
    for (let { image, url } of imageArrayObjects) {
        await userService.uploadImage(url.data.artwork[0].upload_url, image);
    }
}

export default async function get_preassigned_urls(imageArray) {
    const results = []; // Array to hold the results
    for (const image of imageArray) {
      await new Promise(resolve => setTimeout(resolve, 500));
      const extension = 'jpeg' //get_file_extension(image);
      const url = await userService.getPreassignedurl(extension);
      results.push({ image, url: url });
    }
    return results;
}

export function validateUploadForms(formsData) {
    // Use Array.every to check every item meets the condition
    return formsData.every(form => {
      // Destructure the needed fields from each form object
      const { title, location, approximateYear, artist } = form;

      // Check if the fields are not empty, undefined, or only whitespace
      const isTitleValid = true//title && title.trim() !== '';
      const isLocationValid = true//location && location.trim() !== '';
      const isYearValid = true//approximateYear && approximateYear.trim() !== '';
      
      // Return true if all conditions are met, false otherwise
      return isTitleValid && isLocationValid && isYearValid && artist;
    });
  }