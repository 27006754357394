import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
    localStorage.setItem('i18nextLng', languageCode);
  };

  return (
  <>
    {/* <div className="fixed bottom-0 left-0 bg-gray-800 text-white p-3 z-50 rounded-tr-lg">
      <button className="mx-1 p-1 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500" onClick={() => changeLanguage('en')}>
        English
      </button>
      <button className="mx-1 p-1 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500" onClick={() => changeLanguage('zh-CN')}>
        中文
      </button>
    </div> */}

    <div className="fixed bottom-0 left-0 bg-[#F2C14E] text-black p-3 z-50 rounded-tr-lg">
      <button className="mx-1 p-1 hover:bg-[#f0b52e] focus:outline-none focus:ring-2 focus:ring-[#f0b52e]" onClick={() => changeLanguage('en')}>
        English
      </button>
      <button className="mx-1 p-1 hover:bg-[#f0b52e] focus:outline-none focus:ring-2 focus:ring-[#f0b52e]" onClick={() => changeLanguage('zh-CN')}>
        中文
      </button>
    </div>
  </>

  );
};

export default LanguageSwitcher;
    