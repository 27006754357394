import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from '../media/icons/add.svg';
import _, { Spinner } from './customComponents/buttons'
import { useTranslation } from 'react-i18next'

function DragDropPage({ onDragDrop }) {
  const [isUploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation()

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      // Start by assuming no error; reset previous error messages
      setErrorMessage('');
    
      // Begin the uploading process
      setUploading(true);
    
      const totalFiles = acceptedFiles.length + rejectedFiles.length;
    
      // Check if more than the allowed number of files were attempted to be uploaded
      if (totalFiles > 25) {
        // If so, inform the user and halt the upload process
        setErrorMessage("Please drop no more than 25 files at a time.");
        setUploading(false); // Immediately stop uploading as we're not proceeding
        return; // Exit the function to prevent further execution
      }
    
      // Proceed with file processing
      onDragDrop(acceptedFiles)
        .catch((error) => {
          // Handle any errors that occur during the onDragDrop process
          console.error("Error during file processing:", error);
          setErrorMessage("An error occurred while uploading the files.");
        })
        .finally(() => {
          // Completion of the process, either successful or with error
          setUploading(false);
    
          // Reset the error message after a short delay to clear the message
          // This ensures the user sees the error before it is cleared
          setTimeout(() => {
            setErrorMessage('');
          }, 5000); // Adjust the delay as needed
        });
    },
    multiple: true,
    accept: 'image/*',
    maxFiles: 25,
  });

  return (
    <div className="introduction-container flex flex-col justify-center items-center h-screen">
      <div {...getRootProps()} className={`${isUploading ? 'uploading' : ''} drag-upload-box text-center flex flex-col items-center justify-center p-10 border-2 border-dashed`}>
        <input {...getInputProps()} disabled={isUploading}/>
        {isUploading ? (
          <div style={{margin: '20px 300px'}}>
            <Spinner/>
          </div>
          ) : isDragActive ? (
          <h1>
            {t('Drop the files here...')}</h1>
        ) : (
          <>
            <UploadIcon />
            {/* <h1>Drag & Drop <b>Images</b> Here</h1> */}
            <h1>{t('Drag & Drop Images Here')}</h1>
            {errorMessage && <p className="error">{errorMessage}</p>}
          </>
        )}
      </div>
      {!isUploading && !isDragActive && (
        <div className='upload-options text-center mt-4'>              
          {/* <span>Or <u>browse files</u> from your device</span> */}
          <span>{t('Or browse files from your device')}</span>
          <a className='upload-button' {...getRootProps()}> {t('Choose Files')} </a>
        </div>
      )}
    </div>
  );
}

  
export default DragDropPage;
