import React from 'react';
import ArtworkComponent from './modules/artwork.module'
import artworkSample from './modules/samples/artworks.sample.colours.json'

const Background = ({ opacity, className = ''  }) => {

  const backgroundStyle = {
    opacity: opacity,
  };

  const backgroundArtworks = artworkSample;
  const numColumns = 8; // Adjust as needed

  // Split the artwork array into smaller arrays for each column
  const artworksPerColumn = Math.ceil(backgroundArtworks.length / numColumns);
  const columns = [];
  for (let i = 0; i < numColumns; i++) {
    const columnArtworks = backgroundArtworks.slice(i * artworksPerColumn, (i + 1) * artworksPerColumn);
    columns.push(
      <div className={`column ${i % 2 === 0 ? 'move-up' : 'move-down'}`} key={i}>
        {renderArtworks(columnArtworks, i)}
      </div>
    );
  }

  return <div className={`background ${className}`} style={backgroundStyle}>{columns}</div>;
};

const renderArtworks = (columnArtworks, columnIndex) => {
  return columnArtworks.map((artwork, index) => (
    <ArtworkComponent
      key={`${columnIndex}-${index}`} // Unique key for each artwork
      src={artwork.artwork_images.main_600}
      alt={`Artwork ${columnIndex}-${index}`}
    />
  ));
};

export default Background;
