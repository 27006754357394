import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Tooltip } from 'react-tooltip'
import Logo from '../logo.svg';
import SmLogo from '../media/icons/sm_logo_artera.svg'
import DefaultImage from '../media/profile-picture.svg';
import supabase from '../services/supabaseClient';
import userService from '../services/user.service';
import { useTranslation } from 'react-i18next'

// const InviteSection = ({ referral_code, referrals_count }) => {
//   const [copySuccess, setCopySuccess] = useState('');

//   const handleCopyCode = async () => {
//     try {
//       await navigator.clipboard.writeText(referral_code);
//       setCopySuccess('Copied!');
//       setTimeout(() => setCopySuccess(''), 2000); // Hide confirmation after 2 seconds
//     } catch (err) {
//       console.error('Failed to copy!', err);
//     }
//   };

//   return (
//     <div className='invitation-screen'>

//       <div className='p-5'>
//         <p className='text-sm invitation-message'>Share your invite with a friend so they can start using Artera</p>
//       </div>

//       <div className='text-lg mb-1'>
//         <span>{referrals_count} referrals</span>
//       </div>

//       <div className='flex justify-center items-center space-x-4 mb-4'>
        
//         <div className='referal-code text-lg relative' onClick={handleCopyCode} onMouseEnter={() => setCopySuccess('Copy')} onMouseLeave={() => setCopySuccess('')}>
//           <img src={SmLogo} alt="Logo" />
//           <span>{referral_code}</span>
//           <div className='copy-code-message'>
//             {copySuccess}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };



const calculateCountdownTime = (date) => {

  if (isNaN(Date.parse(date))){
    return date
  }

  const countDownDate = new Date(date).getTime()

  
  const now = new Date().getTime();

  const distance = countDownDate - now;
  if (distance < 0) {
    return "EXPIRED";
  }
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
};

const InviteSection = ({ referral }) => {
  const { t } = useTranslation()

  const initialCopyText = t('Tap to copy code')

  const [copySuccess, setCopySuccess] = useState(()=>`[${initialCopyText}]`);
  
  // Initialize countdownTime with a default value
  const [countdownTime, setCountdownTime] = useState(calculateCountdownTime(referral?.reset_date));
  const intervalRef = useRef(null); // Use ref to persist interval ID across re-renders

  useEffect(() => {
    // Only set up the interval if it doesn't already exist
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setCountdownTime(calculateCountdownTime(referral?.reset_date));
      }, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [referral?.reset_date]); // Depend on countDownDate to reset interval when date changes

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(referral?.referral_code);
      const copiedCopyText = t('code copied')

      setCopySuccess(`[${copiedCopyText}]`);

      const initialCopyText = t('Tap to copy code')
      setTimeout(() => setCopySuccess(`[${initialCopyText}]`), 2000);
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  const renderMenuTitle = () => {
    if (referral?.interval_usage === 0) return t('No Invites to share');
    const referral_available_invites = referral?.interval_usage;
    const referral_available_invites_message = typeof referral_available_invites === 'number' ? `${referral_available_invites}x` : t('Unlimited');    
    const invite_text = referral?.referral_available_invites === 1 ? t('Invite to share') : t('Invites to share');
    return `${referral_available_invites_message} ${invite_text}`
  };

  const renderMenuParagraph = () => {
    if(referral?.referral_available_invites == 0) {
      const resetDate = new Date(referral?.reset_date);
      const formattedDate = resetDate.toLocaleDateString('en-US', {
        year: 'numeric', 
        month: 'long', 
        day: 'numeric'
      });
      return `You have reached your invite limit. Please wait until ${formattedDate}.`;
    }
    return `Share your invite code with a friend so they can start using Artera as an artist.`;
  }


  return (
    <>
    <div className='invitation-screen'>
      <div className='mb-1'>
        <span className='text-lg' style={{fontSize: '30px'}}>
        {t('Referral Code')}
        </span>
      </div>
      <div className='text-md'>
        <span className='text-white' style={{fontSize: '20px', opacity: 0.85}}>{renderMenuTitle()}</span>
      </div>

      <div className='text-sm mb-2'>
        <span className='text-white countdown-timer' style={{opacity: 0.6, position: 'absolute', left: '50%', transform: 'translate(-50%)'}}>
        {countdownTime}
        </span>
      </div>

      <div className='p-5'>
        <p className='text-sm invitation-message'>{t(renderMenuParagraph())}</p>
      </div>
      
      
      
      <div className='flex justify-center items-center space-x-4  mb-2'>
        
        <div className='referal-code text-lg relative' onClick={handleCopyCode}>
          <img src={SmLogo} alt="Logo" />
          <span>{referral?.referral_code}</span>
        </div>
        
        <div className='text-lg'>
          <span>
            {referral?.total_referred} {t('referrals')}
          </span>
          <span style={{display: 'flex', color: '#5A5956'}}>
            <div style={{margin: 'auto', marginRight: '5px'}}>
              <svg style={{width: '15px', 'height': '15px'}}
                width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1.13477H9.5V3.69549M3 1.13477H0.5V3.69549" stroke="#5A5956" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 10.8655V6.61473C5.00286 6.34194 4.95249 6.07131 4.85187 5.81873C4.75124 5.56615 4.60237 5.3367 4.414 5.14384L0.5 1.13477M6.5 4.20764L9.5 1.13477" stroke="#5A5956" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div style={{margin: 'auto', fontSize: '15px'}}>
              {referral?.total_generations_referred}
            </div>
            <div className='tooltip' style={{margin: 'auto', marginLeft: '10px'}}>
              <svg  style={{width: '10px', 'height': '10px'}} width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.7 4.5H3.3V2.7H2.7V4.5ZM3 2.1C3.085 2.1 3.1563 2.0712 3.2139 2.0136C3.2715 1.956 3.3002 1.8848 3.3 1.8C3.3 1.715 3.2712 1.6438 3.2136 1.5864C3.156 1.529 3.0848 1.5002 3 1.5C2.915 1.5 2.8438 1.5288 2.7864 1.5864C2.729 1.644 2.7002 1.7152 2.7 1.8C2.7 1.885 2.7288 1.9563 2.7864 2.0139C2.844 2.0715 2.9152 2.1002 3 2.1ZM3 6C2.585 6 2.195 5.9212 1.83 5.7636C1.465 5.606 1.1475 5.3923 0.8775 5.1225C0.6075 4.8525 0.3938 4.535 0.2364 4.17C0.079 3.805 0.0002 3.415 0 3C0 2.585 0.0788 2.195 0.2364 1.83C0.394 1.465 0.6077 1.1475 0.8775 0.8775C1.1475 0.6075 1.465 0.3938 1.83 0.2364C2.195 0.079 2.585 0.0002 3 0C3.415 0 3.805 0.0788 4.17 0.2364C4.535 0.394 4.8525 0.6077 5.1225 0.8775C5.3925 1.1475 5.6063 1.465 5.7639 1.83C5.9215 2.195 6.0002 2.585 6 3C6 3.415 5.9212 3.805 5.7636 4.17C5.606 4.535 5.3923 4.8525 5.1225 5.1225C4.8525 5.3925 4.535 5.6063 4.17 5.7639C3.805 5.9215 3.415 6.0002 3 6ZM3 5.4C3.67 5.4 4.2375 5.1675 4.7025 4.7025C5.1675 4.2375 5.4 3.67 5.4 3C5.4 2.33 5.1675 1.7625 4.7025 1.2975C4.2375 0.8325 3.67 0.6 3 0.6C2.33 0.6 1.7625 0.8325 1.2975 1.2975C0.8325 1.7625 0.6 2.33 0.6 3C0.6 3.67 0.8325 4.2375 1.2975 4.7025C1.7625 5.1675 2.33 5.4 3 5.4Z" fill="#5A5956"/>
              </svg>

              <span class="tooltiptext">
                {t('Generational referrals count all the people invited by you and your friends.')}
              </span>
            </div>
          </span>
        </div>
      </div>

      <div className='copy-code-message mb-2' style={{cursor: 'pointer'}} onClick={handleCopyCode}>
        {t(copySuccess)}
      </div>

    </div>
    <div style={{
      height: '1px',
      backgroundColor: '#FFFFFF',
      opacity: 0.3,
      marginTop: '20px',
      marginBottom: '20px'
    }}>
    </div>
    </>
  );
};


const Navbar = ({ isAuthenticated = true }) => {
  const [user, setUser] = useState(null);
  const [referral, setReferral] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null); 
  const { t } = useTranslation()

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const session = await supabase.auth.getSession();
      setUser(session?.data?.session?.user?.user_metadata ?? null);
      if (!session?.data?.session?.user?.user_metadata?.profile_id) {
        navigate('/login');
      }
    };

    const fetchInviteStatus = async () => {
      try {
        const status = await userService.getInviteStatus();
        setReferral(status.data);
      } catch (error) {
        console.error('Failed to fetch invite status:', error);
      }
    };

    fetchUser();
    fetchInviteStatus();
  }, [navigate]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Add when the dropdown is shown and remove listener on cleanup
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);


  const onLogout = async () => {
    navigate('/logout');
  };

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const profile_picture = user?.profile_images?.profile_picture?.main_300 || DefaultImage;

  return (
    <nav className="fixed top-0 left-0 bg-transparent w-full flex justify-between items-center py-4 px-6 z-50">
      <div className="flex items-center">
        <div className="logo">
          <img src={Logo} alt="Logo" className="h-6 w-auto"/>
        </div>
      </div>
      <div className="flex items-center ml-auto" ref={dropdownRef}>
        {isAuthenticated && user ? (
          <>
            <div className="mr-4 hidden sm:inline text-yellow" style={{textTransform: 'lowercase' }}>@{user?.profile_handle}</div>     
            <div className={`pfp ${profile_picture ? 'bordered' : ''} relative`}>
              <img src={profile_picture} alt="Profile" className="h-10 w-10 rounded-full cursor-pointer" style={{objectFit: 'cover'}} onClick={toggleDropdown}/>
              {showDropdown && (
                <div className="absolute navbar-menu right-0 mt-2 py-2 w-48 rounded-md shadow-xl z-20">
                  <div className='m-25'>
                    {referral && <InviteSection referral={referral} />}
                    <a className="cursor-pointer px-4 py-2 hover:bg-gray-100" onClick={onLogout}>
                      <span>
                      {t('Logout')}
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div></div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
