import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/home.component";
import Login from './components/login.component';
import Waitinglist from './components/waitinglist.component'
import Logout from './components/logout.component'
import "./App.css";
import LanguageSwitcher from './components/translate.component'
import './i18n'

import supabase from "./services/supabaseClient";
import { NextUIProvider } from "@nextui-org/system";
import { VendorProvider } from './providers/useVendor'
import Cookies from 'js-cookie'

// const isAuthenticated = async () => {
//   const { data, error } = await supabase.auth.getSession();
//   return data?.session?.user?.app_metadata !== undefined;
// };

const isAuthenticated = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (data?.session?.user?.user_metadata?.profile_id) {
    const sessionTokenString = JSON.stringify(data.session);
    const chunkSize = 3180;
    const tokenList = [];

    function setSessionCookie(name, value) {
      Cookies.set(name, value, { path: '/' });
    }

    // Clear previous session cookies
    const existingCookies = Cookies.get();
    Object.keys(existingCookies).forEach(cookie => {
      if (cookie.startsWith('sb-exnjkcxnrbrrjlqqtqch-auth-token.')) {
        Cookies.remove(cookie, { path: '/' });
      }
    });

    // Process and store each chunk of the session token in cookies
    for (let i = 0; i < sessionTokenString.length; i += chunkSize) {
      const chunk = sessionTokenString.slice(i, i + chunkSize);
      tokenList.push(chunk);
      setSessionCookie(`sb-exnjkcxnrbrrjlqqtqch-auth-token.${tokenList.length - 1}`, chunk);
    }

    // Store the entire session in localStorage
    localStorage.setItem('session', JSON.stringify(data.session));
    return true;
  } else {
    localStorage.removeItem('session');
    // Remove any session cookies if authentication fails or session is not available
    const existingCookies = Cookies.get();
    Object.keys(existingCookies).forEach(cookie => {
      if (cookie.startsWith('sb-exnjkcxnrbrrjlqqtqch-auth-token.')) {
        Cookies.remove(cookie, { path: '/' });
      }
    });
    return false;
  }
};

// Corrected and made asynchronous ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const checkAuth = async () => {
      const isAuth = await isAuthenticated();
      setAuth(isAuth);
      setLoading(false)
    };

    checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (!auth) {
    return <Navigate to="/login" />;
  }

  return children;
};

const PublicRoute = ({ children }) => {
  const [auth, setAuth] = useState(false);
  
  useEffect(() => {
    const checkAuth = async () => {
      const isAuth = await isAuthenticated();
      setAuth(isAuth);
    };

    checkAuth();
  }, []);

  if (auth) {
    return <Navigate to="/" />;
  }

  return children;
}

const IsSiteAvailableRoute = ({ children }) =>{
  if (process.env.REACT_APP_SITE_AVAILABLE=='false') return <Waitinglist />
  return children;
};

function App() {
  return (
    <NextUIProvider theme="dark">
      <VendorProvider>
      <div className="App">
        <Router>
          <Routes>

            <Route path="/" element={
              <IsSiteAvailableRoute>
              <ProtectedRoute>
              <Home/>
              </ProtectedRoute>
              </IsSiteAvailableRoute>
            } />

            <Route path="/login" element={
            <IsSiteAvailableRoute>
            <PublicRoute>              
            <Login />
            </PublicRoute>
            </IsSiteAvailableRoute>
            } />

          <Route path="/logout" element={
            <IsSiteAvailableRoute>
            <ProtectedRoute>
            <Logout />
            </ProtectedRoute>
            </IsSiteAvailableRoute>
          } />

          </Routes>
        </Router>
      </div>
      </VendorProvider>
      <LanguageSwitcher />
    </NextUIProvider>
  );
}

export default App;
