import React, { useState, useEffect, useCallback, useRef, Component } from 'react';
import userService from '../../services/user.service';
import DefaultImage from '../../media/profile-picture.svg';
import { debounce } from 'lodash';
import { useTranslation, withTranslation } from 'react-i18next'



class ArtistSearchField extends Component {


  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      filteredItems: [],
      isLoading: false,
      showDropdown: false,
      selectedArtist: null,
      onReset: null,
    };

    this.wrapperRef = React.createRef();
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelectArtist = this.handleSelectArtist.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.debouncedSearch = debounce(this.searchArtists.bind(this), 250);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const { defaultProfile, onReset } = this.props;
    this.setState({
      selectedArtist: defaultProfile || null,
      searchTerm: defaultProfile?.profile_name || defaultProfile?.profile_handle || '',
      onReset: onReset
    });
  }
  
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.debouncedSearch.cancel();
  }

  handleClickOutside(event) {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ showDropdown: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { defaultProfile, onReset } = this.props
    if (this.props.onReset !== prevProps.onReset || this.props.defaultProfile !== prevProps.defaultProfile) {
      this.setState({
        selectedArtist: defaultProfile || null,
        searchTerm: defaultProfile?.profile_name || defaultProfile?.profile_handle || '',
        onReset: onReset
      });
    }
  }

  async searchArtists(query) {
    this.setState({ isLoading: true });
    if (!query) {
      this.setState({ filteredItems: [], isLoading: false });
      return;
    }

    try {
      const response = await userService.searchArtist(query);
      const items = response.data;

      const latestItemsFirst = items.reverse();
      const seenIds = new Set();
      const uniqueItems = latestItemsFirst.filter(item => {
        return seenIds.has(item.profile_id) ? false : seenIds.add(item.profile_id);
      });

      const mappedItems = uniqueItems.map(item => ({
        id: item.profile_id,
        profile_picture: item.profile_images?.profile_picture?.main_150 || DefaultImage,
        name: item.profile_handle,
      }));

      this.setState({ filteredItems: mappedItems });
    } catch (error) {
      console.error("Failed to search artists:", error);
      this.setState({ filteredItems: [] });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleSearch(event) {
    const newValue = event.target.value;
    this.setState({ searchTerm: newValue }, () => {
      if (!newValue) {
        this.setState({ selectedArtist: null, isLoading: false });
      } else {
        this.debouncedSearch(newValue);
      }
    });
  }

  handleSelectArtist(artist) {
    this.props.onChange('artist', {
      id: artist.id,
      profile_name: artist.name,
      profile_picture: artist.profile_picture || DefaultImage
      
    });
    this.setState({ searchTerm: artist.name, selectedArtist: artist, showDropdown: false });
  }

  render() {
    const { searchTerm, selectedArtist, isLoading, showDropdown, filteredItems } = this.state;
    const { disbaled } = this.props; // Note: Should it be "disabled" instead of "disbaled"?
    const { t } = this.props;

    return (
      <div className="custom-field relative" ref={this.wrapperRef}>
        <label htmlFor="artist" className="block text-sm font-medium text-white text-left">Artist</label>
        <div className="mt-1 flex items-center w-full">
          {selectedArtist && (
            <img src={selectedArtist.profile_picture || DefaultImage} alt="Selected artist" className="h-6 w-6 rounded-full" style={{objectFit: 'cover'}} />
          )}
          <input
            id="artist"
            placeholder={!selectedArtist ? t("Search Artist") : ""}
            className={`flex-1 ${selectedArtist ? 'ml-3' : ''} ${isLoading ? 'loading' : ''}`}
            value={searchTerm}
            onChange={this.handleSearch}
            autoComplete="off"
            disabled={disbaled}
            onFocus={() => this.setState({ showDropdown: true })}
          />
          {isLoading && (
            <div className="flex justify-center items-center ">
              <div className="w-8 h-8 bg-transparent border-4 border-t-4 border-gray-900 rounded-full pulse-ring"></div>
            </div>
          )}
          {isLoading === false && (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" style={{
                position: 'absolute',
                right: '10px',
                pointerEvents: 'none', 
            }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
          </svg>
          )}
        </div>

        {showDropdown && searchTerm && !isLoading && (
          <div className="mt-1 w-full rounded-md bg-white shadow-lg z-10">
            <ul
              tabIndex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
              style={{ position: 'absolute', width: '100%', background: 'white', zIndex: '99' }}
              className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            >
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <li
                    key={item.id}
                    className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                    onClick={() => this.handleSelectArtist(item)}
                  >
                    <div className="flex items-center">
                      <img src={item.profile_picture} alt={item.name} className="h-6 w-6 rounded-full" />
                      <span className="ml-3 block font-normal truncate">{item.name}</span>
                    </div>
                  </li>
                ))
              ) : (
                <li className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                  <span className="ml-3 block font-normal truncate">{t("No items found.")}</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

class InstitutionalSearchFieldComponent extends ArtistSearchField {

  componentDidUpdate(prevProps) {
    const { defaultProfile, onReset } = this.props
    if (this.props.onReset !== prevProps.onReset || this.props.defaultProfile !== prevProps.defaultProfile) {
      this.setState({
        selectedArtist: defaultProfile || null,
        searchTerm: defaultProfile?.profile_name || defaultProfile?.profile_handle || '',
        onReset: onReset
      });
    }   
  
  }

  async searchArtists(query) {
    this.setState({ isLoading: true });
    if (!query) {
      this.setState({ filteredItems: [], isLoading: false });
      return;
    }

    try {
      const response = await userService.searchInstitutions(query);
      const items = response.data;

      const latestItemsFirst = items.reverse();
      const seenIds = new Set();
      const uniqueItems = latestItemsFirst.filter(item => {
        return seenIds.has(item.profile_id) ? false : seenIds.add(item.profile_id);
      });

      const mappedItems = uniqueItems.map(item => ({
        id: item.profile_id,
        profile_picture: item.profile_images?.profile_picture?.main_150 || DefaultImage,
        name: item.profile_handle,
      }));

      this.setState({ filteredItems: mappedItems });
    } catch (error) {
      console.error("Failed to search artists:", error);
      this.setState({ filteredItems: [] });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleSelectArtist(artist) {
    this.props.onChange('institution', {
      id: artist.id,
      profile_name: artist.name,
      profile_picture: artist.profile_picture || DefaultImage
      
    });
    this.setState({ searchTerm: artist.name, selectedArtist: artist, showDropdown: false });
  }

  render() {
    const { searchTerm, selectedArtist, isLoading, showDropdown, filteredItems } = this.state;
    const { disbaled } = this.props; // Note: Should it be "disabled" instead of "disbaled"?
    const { t } = this.props;

    return (
      <div className="custom-field relative" ref={this.wrapperRef}>
        <label htmlFor="location" className="block text-sm font-medium text-white text-left">{t('Artwork Location')}</label>
        <div className="mt-1 flex items-center w-full">
          {selectedArtist && (
            <img src={selectedArtist.profile_picture || DefaultImage} alt="Selected artist" className="h-6 w-6 rounded-full" style={{objectFit: 'cover'}} />
          )}
          <input
            id="location"
            placeholder={!selectedArtist ? t("Search Location") : ""}
            className={`flex-1 ${selectedArtist ? 'ml-3' : ''} ${isLoading ? 'loading' : ''}`}
            value={searchTerm}
            onChange={this.handleSearch}
            autoComplete="off"
            disabled={disbaled}
            onFocus={() => this.setState({ showDropdown: true })}
          />
          {isLoading && (
            <div className="flex justify-center items-center ">
              <div className="w-8 h-8 bg-transparent border-4 border-t-4 border-gray-900 rounded-full pulse-ring"></div>
            </div>
          )}
          {isLoading === false && (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" style={{
                position: 'absolute',
                right: '10px',
                pointerEvents: 'none', 
            }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
          </svg>
          )}
        </div>

        {showDropdown && searchTerm && !isLoading && (
          <div className="mt-1 w-full rounded-md bg-white shadow-lg z-10">
            <ul
              tabIndex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
              style={{ position: 'absolute', width: '100%', background: 'white', zIndex: '99' }}
              className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            >
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <li
                    key={item.id}
                    className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                    onClick={() => this.handleSelectArtist(item)}
                  >
                    <div className="flex items-center">
                      <img src={item.profile_picture} alt={item.name} className="h-6 w-6 rounded-full" />
                      <span className="ml-3 block font-normal truncate">{item.name}</span>
                    </div>
                  </li>
                ))
              ) : null}
            </ul>
          </div>
        )}
        
      </div>
    );
  }
}

class ProfileSearchFieldComponent extends ArtistSearchField {

  componentDidUpdate(prevProps) {
    const { defaultProfile, onReset } = this.props
    if (this.props.onReset !== prevProps.onReset || this.props.defaultProfile !== prevProps.defaultProfile) {
      this.setState({
        selectedArtist: defaultProfile || null,
        searchTerm: defaultProfile?.profile_name || defaultProfile?.profile_handle || '',
        onReset: onReset
      });
    }   
  
  }

  async searchArtists(query) {
    this.setState({ isLoading: true });
    if (!query) {
      this.setState({ filteredItems: [], isLoading: false });
      return;
    }

    try {
      const response = await userService.searchProfiles(query);
      const items = response.data;

      const latestItemsFirst = items;
      const seenIds = new Set();
      const uniqueItems = latestItemsFirst.filter(item => {
        return seenIds.has(item.profile_id) ? false : seenIds.add(item.profile_id);
      });

      const mappedItems = uniqueItems.map(item => ({
        id: item.profile_id,
        profile_picture: item.profile_images || DefaultImage,
        name: item.profile_handle,
      }));

      this.setState({ filteredItems: mappedItems });
    } catch (error) {
      console.error("Failed to search artists:", error);
      this.setState({ filteredItems: [] });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleSelectArtist(artist) {
    this.props.onChange('artist', {
      profile_id: artist.id,
      profile_handle: artist.name,
      profile_images: artist.profile_picture || DefaultImage
    });
    this.setState({ searchTerm: artist.name, selectedArtist: artist, showDropdown: false });
  }

  render() {
    const { searchTerm, selectedArtist, isLoading, showDropdown, filteredItems } = this.state;
    const { disbaled } = this.props; // Note: Should it be "disabled" instead of "disbaled"?
    const { t } = this.props;

    return (
      <div className="custom-field relative" ref={this.wrapperRef}>
        <label htmlFor="profile" className="block text-sm font-medium text-white text-left">
          {t("Profile")}
        </label>
        <div className="mt-1 flex items-center w-full">
          {selectedArtist && (
            <img src={selectedArtist.profile_picture || DefaultImage} alt="Selected profile" className="h-6 w-6 rounded-full" style={{objectFit: 'cover'}} />
          )}
          <input
            id="profile"
            placeholder={!selectedArtist ? t("Search profile") : ""}
            className={`flex-1 h-[60px] ${selectedArtist ? 'ml-3' : ''} ${isLoading ? 'loading' : ''}`}
            value={searchTerm}
            onChange={this.handleSearch}
            autoComplete="off"
            disabled={disbaled}
            onFocus={() => this.setState({ showDropdown: true })}
          />

          {isLoading && (
            <div className="flex justify-center items-center ">
              <div className="w-8 h-8 bg-transparent border-4 border-t-4 border-gray-900 rounded-full pulse-ring"></div>
            </div>
          )}

          {isLoading === false && (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" style={{
                position: 'absolute',
                right: '10px',
                pointerEvents: 'none', 
            }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
          </svg>
          )}

        </div>

        {showDropdown && searchTerm && !isLoading && (
          <div className="mt-1 w-full rounded-md bg-white shadow-lg z-10">
            <ul
              tabIndex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
              style={{ position: 'absolute', width: '100%', background: 'white', zIndex: '99' }}
              className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            >
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <li
                    key={item.id}
                    className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                    onClick={() => this.handleSelectArtist(item)}
                  >
                    <div className="flex items-center">
                      <img src={item.profile_picture} alt={item.name} className="h-6 w-6 rounded-full" />
                      <span className="ml-3 block font-normal truncate">{item.name}</span>
                    </div>
                  </li>
                ))
              ) : null}
            </ul>
          </div>
        )}
        
      </div>
    );
  }
}

export default withTranslation()(ArtistSearchField);
export const InstitutionalSearchField = withTranslation()(InstitutionalSearchFieldComponent);
export const ProfileSearchField = withTranslation()(ProfileSearchFieldComponent);

