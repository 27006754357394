import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../services/supabaseClient';

export default function Logout() {
    const navigate = useNavigate();
  
    useEffect(() => {
      const doLogout = async () => {
        await supabase.auth.signOut({
          scope: 'local'
      });
        navigate('/login');
      };
  
      doLogout();
    }, [navigate]);
}; 