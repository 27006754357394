import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-spring-3d-carousel';
import { config } from 'react-spring';
import {Image} from "@nextui-org/image";
import { Icon } from '@nextui-org/react'; // Assuming you want to use Next UI for icons
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function ImageWithGlow({ src, alt }) {
  return (
    <div className="image-with-glow">
      <div className="image-with-glow__background" style={{ backgroundImage: `url(${src})` }}></div>
      <img src={src} alt={alt} className="image-with-glow__foreground"/>
    </div>
  );
}

function DeleteIcon({ onClick }) {
  return (
    <div className='delete-icon' onClick={onClick}>
      <FontAwesomeIcon icon={faTimes} />
    </div>
  );
}

export function CarouselThumbnailsSlider({ imageArray, selectedImageIndex, handleImageClick, handleImageDelete}) {
  
  const swiperRef = useRef(null);

  const onImageDelete = (index) => {
    handleImageDelete(index); // This should handle state update in the parent component
    setTimeout(() => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.update(); // Make sure swiper updates to re-sync with React state
      }
    }, 0);
  };

  return (
    <div className="relative">
      <Swiper
        ref={swiperRef}
        spaceBetween={10}
        slidesPerView={4}
        navigation={true}
        onSwiper={(swiper) => (swiperRef.current = swiper)} 
        onSlideChange={(swiper) => {
        }}
        className="thumbnailContainer"
      >
        {imageArray.map((image, index) => (
          <SwiperSlide key={index} className={`thumbnail thumbnail-index-${index} ${index === selectedImageIndex ? 'isThumbnailSelected' : ''}`}>
            {/* <DeleteIcon onClick={() => onImageDelete(index)} /> */}
            <img
              src={URL.createObjectURL(image)}
              alt={`thumb-${index}`}
              className={`thumbnailImage cursor-pointer object-cover w-full h-full ${index === selectedImageIndex ? 'isThumbnailSelected' : ''} `}
              onClick={() => handleImageClick(index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>

  );
}

export function OriginalCaroselThumbnailsSlider({imageArray, selectedImageIndex, handleImageClick}){
  return (
    <div className="thumbnailContainer">
      {imageArray.length > 0 && imageArray.map((image, index) => (
        <div 
          key={index} 
          onClick={() => handleImageClick(index)} 
          className={`thumbnail ${index === selectedImageIndex ? 'isThumbnailSelected' : ''}`}
        >
          <img
            src={URL.createObjectURL(image)}
            alt={`thumb-${index}`}
            className={`thumbnailImage ${index === selectedImageIndex ? 'isThumbnailSelected' : ''}`}
          />
        </div>
      ))}
    </div>
  )
}



export default function ImageCarousel({ images, onSlideChange = () => {} }) {
  const [currentSlide, setCurrentSlide] = useState(0);
    const offsetRadius = 2;
    const showNavigation = false; // Set to false to hide navigation buttons
  
    const handleImageClick = index => {
      setCurrentSlide(index);
      onSlideChange(index)
    };
  
    // Convert images to slides with conditional styling
    const slides = images.map((image, index) => ({
      key: index,
      content: (
        <div className='image-preview' 
        style={{
          // boxShadow: index === currentSlide ? '0px 0px 35px 15px rgba(242, 188, 64, 0.4)' : '',
        }}
        onClick={() => handleImageClick(index)} // Changed this line
        >
          <Image
            src={URL.createObjectURL(image)}
            alt={`slide-${index}`}
            // style={{
            //   width: '100%',
            //   height: 'auto',
            //   opacity: index === currentSlide ? 1 : 1,
            //   filter: index === currentSlide ? 'none' : 'blur(1px)' // Blur for non-centered images
            // }}
          />
        </div>
      )
    }));
  
    return (
      <div style={{ width: '60%', height: '500px', margin: '0 auto' }}>
        <Carousel
          slides={slides}
          goToSlide={currentSlide}
          offsetRadius={offsetRadius}
          showNavigation={showNavigation}
          animationConfig={config.gentle}
          onSlideChange={index => setCurrentSlide(index)} // Update currentSlide on slide change
        />
      </div>
    );
}



export function ImageCarouselScroll({ 
  imageArray = [], 
  onSlideChange = () => {}, 
  onImageDelete = () => {},
  selectedImageIndexProp = 0 
}) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);

  // Create or revoke image URL as needed
  const updateImageDisplay = (index) => {
    // Revoke the previous image URL to prevent memory leaks
    if (selectedImage) URL.revokeObjectURL(selectedImage);

    // Set the new image URL if available
    const newSelectedImage = imageArray.length > 0 ? imageArray[index] : null;
    setSelectedImage(newSelectedImage ? URL.createObjectURL(newSelectedImage) : null);
  };

  // Handle clicks on images
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    onSlideChange(index);
  };

  const handleImageDelete = (index) => {
    const thumbnail = document.querySelector(`.thumbnail-index-${index}`);
    if (thumbnail) {
      thumbnail.remove();
      onImageDelete(index)
    } else {
      console.log('Thumbnail not found for index', index);
    }
  }

  useEffect(() => {
    setSelectedImageIndex(0);
    updateImageDisplay(0);
  }, [imageArray])

  // Effect for external prop changes
  useEffect(() => {
    if (selectedImageIndex !== selectedImageIndexProp) {
      setSelectedImageIndex(selectedImageIndexProp);
      updateImageDisplay(selectedImageIndexProp);
    }
  }, [selectedImageIndexProp]);

  // Effect for selectedImageIndex changes
  useEffect(() => {
    updateImageDisplay(selectedImageIndex);
    // Cleanup function to revoke URL on component unmount
    return () => {
      if (selectedImage) URL.revokeObjectURL(selectedImage);
    };
  }, [selectedImageIndex]);

  return (
    <div className="carouselContainer">
      <div className="imageWrapper">
        {selectedImage ? (
          <ImageWithGlow
            src={selectedImage}
            alt={`slide-${selectedImageIndex}`}
            className="displayedImage"
          />
        ) : (
          <div className="noImage">No images available</div>
        )}
      </div>

      <CarouselThumbnailsSlider 
        imageArray={imageArray} 
        selectedImageIndex={selectedImageIndex}
        handleImageClick={handleImageClick}
        handleImageDelete={handleImageDelete}
      />
    </div>
  );
}
