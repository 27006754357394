import React, { useState, useEffect } from 'react';

const defualt = () => {}

export const Spinner = () => (
    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0H4z"></path>
    </svg>
);


/**
 * CustomButton Component
 * 
 * This component is designed to provide a flexible and reusable button that encapsulates several common behaviors, making it ideal for various use cases across an application.
 * 
 * Props:
 * - onClick: A function that the button should execute when clicked. This function can be asynchronous, supporting operations that require waiting, such as fetching data or submitting forms.
 * - className: Optional. Allows custom styling to be passed to the button, leveraging Tailwind CSS for design consistency and responsiveness.
 * - disabled: Optional (defaults to false). If true, the button starts in a disabled state, preventing user interaction until enabled programmatically.
 * - loadingIndicatorOnly: Optional (defaults to false). When true, only the loading spinner is displayed during the loading state, hiding the button's children. This is useful for minimizing visual clutter during operations.
 * - singleUse: Optional (defaults to false). If true, the button becomes permanently disabled after a successful click event. This is particularly useful for submit buttons in forms where duplicate submissions are to be prevented.
 * - children: The content to be displayed inside the button. This can include text, icons, or any React node that fits within a button context.
 * 
 * Behavior:
 * 1. Upon clicking the button, the `onClick` function is executed. The button shows a loading indicator if the operation is asynchronous, providing immediate visual feedback to the user that an action is in progress.
 * 2. If the `onClick` operation succeeds without throwing an error, the button will either become permanently disabled if `singleUse` is true or reset to its original state allowing further interactions.
 * 3. If the `onClick` operation throws an error, the button captures this error in a catch block. The error is logged to the console for debugging purposes, and the button is reset to its original state unless `singleUse` is specified. In the case of `singleUse`, the button remains disabled to prevent further attempts after failure.
 * 4. The `disabled` and `isLoading` states are managed internally, but can be influenced by the props passed to the component, ensuring that the button's behavior aligns with the intended user experience design.
 * 
 * Error Handling:
 * - The component includes error handling within the asynchronous `handleClick` function. If an error occurs during the execution of the `onClick` function, it is caught in the catch block. The component logs the error to the console for developer visibility and resets the loading state, allowing the user to attempt the action again if `singleUse` is not enabled. This approach ensures that unexpected issues do not leave the button in a permanently disabled or loading state, improving the overall robustness of the user interface.
 * 
 * Usage:
 * The `CustomButton` can be used in various parts of an application where button interactions require feedback or specific behavior patterns, such as form submissions, asynchronous data loading, or actions that should only be performed once.
 */
const CustomButton = ({ 
    onClick, className = '', textPos='center', disabled = false, loadingIndicatorOnly = false, singleUse = false, style = {}, pause = null, children
    }) => {
    const [isLoading, setIsLoading] = useState(null);
    const [isDisabled, setIsDisabled] = useState(null);

    useEffect(()=>{
        setIsDisabled(false)
        setIsLoading(isLoading)
    }, [])

    useEffect(()=>{
        setIsDisabled(disabled)
    }, [disabled])
  
    const handleClick = async () => {

        setIsLoading(true);
        if (singleUse) setIsDisabled(true);      
        const result = await onClick();

        setIsLoading(false);
      
        if (result) {
          // Success path
          if (!singleUse) {
            setIsDisabled(false); // Re-enable if not singleUse
          }
        } else {
            // Failure or null return from onClick
            setIsDisabled(false);
        }
      };
  
    return (
    <button
        className={`${className} flex justify-${textPos} items-${textPos} relative`}
        style={style}
        onClick={handleClick}
        disabled={isDisabled || isLoading}
      >
        {loadingIndicatorOnly && isLoading ? (
          <Spinner />
        ) : (
          <>
            {children}
            {isLoading && <div className="ml-2"><Spinner /></div>}
          </>
        )}
      </button>
    );
};

export default CustomButton;
