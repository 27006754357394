import React from 'react';

function ArtworkComponent({ src, alt }) {
    return (
    <div className={`artwork-card ${false ? 'staggered' : ''}`}>
        <img src={src} alt={alt} className="artwork-image" />
    </div>
    );
}

export default ArtworkComponent;
