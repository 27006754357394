import React, { useState, useEffect } from 'react';
import ImageCarousel, { ImageCarouselScroll } from './modules/artwork.carosel';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Button, Input, User, Spacer, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem  } from "@nextui-org/react";
import { Image, Modal } from '@nextui-org/react';
import AudioRecorderComponent from './modules/artwork.audio';
import ArtworkUploadForm from './modules/artwork.form'
import _, { validateUploadForms } from '../utils/manageUploads'
import supabase from '../services/supabaseClient';
import userService from '../services/user.service';

function customFormatDateCurrent() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Calculate timezone offset
  const timezoneOffset = -date.getTimezoneOffset();
  const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
  const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
  const timezoneFormatted = (timezoneOffset >= 0 ? "+" : "-") + offsetHours + offsetMinutes;

  // Construct the date string
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}${timezoneFormatted}`;
}


function DetailsPage({ imageArrayPreAssigned, onDetailsSubmit, onReturn}) {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [formsData, setFormsData] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [user, setUser] = useState(null);


  useEffect(() => {
    const fetchUser = async () => {
      const profile = await userService.getProfile(); 
      setUser(profile.data)        
    };
    
    fetchUser();
  }, []); 

  useEffect(() => {
    const initialFormsData = imageArrayPreAssigned.map((content, index) => ({
      isDeleted: false,
      title: 'Untitled',
      location: user.profile_type == 'institution' ? user.profile_name || user.profile_hanlde : '' || '',
      date: customFormatDateCurrent(), 
      type: 'image',
      privacy: 'public',
      image: content.url.data.artwork[0].upload_url, 
      visibility: true,
      approximateYear: '',
      artwork_description: '',

      artist: user.profile_type == 'artist' || user.profile_type == 'user' ? user.profile_id : '',
      artist_profile: '',

      institution: user.profile_type == 'institution' ? user.profile_id : '',
      institution_profile: ''
    }));

    setFormsData(initialFormsData);
    const imageArray = imageArrayPreAssigned.map(content => content?.image || '');
    setImageArray(imageArray);
  }, [imageArrayPreAssigned]);




  useEffect(()=>{
    handleCarouselChange(currentImageIndex)
  },[currentImageIndex])

  useEffect(()=>{
    const valid = validateUploadForms(formsData)
    setValidForm(valid)
  }, [formsData])

  const handleCarouselChange = index => {
    setCurrentImageIndex(index);
  };

  const handleOnDelete = index => {
    const updatedFormsData = formsData.map((item, idx) => {
      if (idx === index) {
        return { ...item, isDeleted: true };
      }
      return item;
    });
  
    setFormsData(updatedFormsData);
    
    const activeItems = updatedFormsData.filter(item => !item.isDeleted);
  
    if (activeItems.length === 0) {
      onReturn();
    }
      handleNextItem();
  };

  const handleInputChange = (key, value, index = null) => {
    const selectedIndex = index !== null ? index : currentImageIndex;
    
    if (key?.target) {
      value = key.target.value;
      key = key.target.name;
    }

    setFormsData(currentFormsData => {
      const updatedFormsData = [...currentFormsData];
      
      // if (key === 'artwork_description' && value != '') {
      //   const commentObject = { 'comment_type': 'text', 'comment_content': value };
      //   updatedFormsData[selectedIndex] = { ...updatedFormsData[selectedIndex], [key]: commentObject };
      // } 
      if (key === 'pricing') {
        if(value){
          updatedFormsData[selectedIndex] = { ...updatedFormsData[selectedIndex], [key]: value };
        }
      } 
      else if (key === 'artist') {
        return currentFormsData.map((item, idx) => {
          if (idx === selectedIndex) {
            return {
              ...item,
              artist: value.id,
              artist_profile: value, 
            };
          }
          return item;
        });
      }
      else if (key === 'institution'){
        return currentFormsData.map((item, idx) => {
          if (idx === selectedIndex){
            return {
              ...item,
              institution: value.id,
              institution_profile: value, 
            };
          }
          return item;
        });
      } 
      else {
        updatedFormsData[selectedIndex] = { ...updatedFormsData[selectedIndex], [key]: value };
      }
  
      return updatedFormsData;
    });
  };

  const handleNextItem = () => {
    setCurrentImageIndex(prevCurrentImageIndex => {
      
      const nextIndex = (prevCurrentImageIndex + 1) % formsData.length;
      
      return nextIndex;
    });
  };

  const handleFormSubmit = async (event) => {
    if (event) event.preventDefault();
  
    // Iterate through each form data item
    const updatedFormsData = formsData.map((item) => {
      // Check if 'pricing' is an empty object
      if (item.pricing && Object.keys(item.pricing).length === 0) {
        // Use destructuring to omit the 'pricing' key from the item
        const { pricing, ...rest } = item;
        return rest; // Return the rest of the item without the 'pricing' key
      }
      return item; // Return the item unchanged if 'pricing' is not empty
    });
  
    return await onDetailsSubmit(updatedFormsData);
  };

  
  return (
    <main className='h-scree overflow-y-auto'>
      <div className="main-container flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 p-4 h-auto md:h-screen flex justify-center items-center order-2 md:order-1">
          <ArtworkUploadForm
          initialFormData={formsData[currentImageIndex]}
          artworksLength={formsData.length}
          profile={user}
          submitValidation={validForm}
          onChange={handleInputChange} 
          onSubmit={handleFormSubmit}
          onNextItem={handleNextItem}
          onReturn={onReturn}
          selectedImageIndexProp={currentImageIndex}
          imageArray={imageArray}
          />
        </div>

        <div className="main-artwork-carousel w-full md:w-1/2 pt-8 p-4 flex flex-col justify-center items-center overflow-x-auto order-1 md:order-2">
          {/* <button className='secondaryButton'>
            <span>
              Add to Collection
            </span>
          </button> */}
          <ImageCarouselScroll 
          imageArray={imageArray} 
          onSlideChange={handleCarouselChange}
          onImageDelete={handleOnDelete}
          selectedImageIndexProp={currentImageIndex}
          />    
      </div>

      </div>
    </main>
  );
}

export default DetailsPage;
