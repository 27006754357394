import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationCN from './public/locales/zh-CN/common.json';
import LocalStorageBackend from 'i18next-localstorage-backend';

const resources = {
  'zh-CN': {
    translation: translationCN
  }
};

// Check for saved language in local storage or default to 'en'
const savedLng = localStorage.getItem('i18nextLng') || 'en';

i18n
  .use(initReactI18next)
  .use(LocalStorageBackend)
  .init({
    resources,
    lng: savedLng, // Set the initial language to the saved language
    supportedLngs: ['en', 'zh-CN'],
    backend: {
      loadPath: '/locales/{{lng}}/common.json',
      addPath: '/locales/add/{{lng}}/common',
      expirationTime: 7 * 24 * 60 * 60 * 1000 // Cache expiration
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

// Function to change language which also updates local storage
function changeLanguage(lng) {
  i18n.changeLanguage(lng);
  localStorage.setItem('i18nextLng', lng); // Save the new language preference
}

export default i18n;
export { changeLanguage };
