import axios from 'axios';
import authHeader from './auth-header';
import { data } from 'autoprefixer';
import imageCompression from 'browser-image-compression';
import _, { convertAndResizeImage } from '../utils/manageUploads'
import supabase from './supabaseClient'; 

const API_URL = process.env.REACT_APP_REST_URL

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: await authHeader(),
});


class UserService {
  
  async getPublicContent() {
    return axiosInstance.post('recommender/random');
  }

  async getPreassignedurl(file_extension) {
    return axiosInstance.get('artworks/upload', {
      params: { extension: file_extension }
    });
  }
  
  async createArtwork(formData) {
    return axiosInstance.post('artworks/create', formData);
  }

  async submitEmail(email, type = 'artist') {
    return axiosInstance.post('register_waiting_list', { email: email, type: type });
  }

  async createArtworks(formDataArray) {
    const results = [];
    for (const formData of formDataArray) {
      await delay(500);
      const createdArtworks = await axiosInstance.post('artworks/create', formData);
      results.push(createdArtworks);
    }
    return results;
  }

  async createRecommendationVector(artworksArray){
    const results = [];
    for (const artwork of artworksArray) {
      await delay(500);
      const createdVector = await axiosInstance.post('artworks/upload_to_vector', {
        "image_url": artwork.data.artwork_images.source,
        "artwork_id": artwork.data.artwork_id
      });
      results.push(createdVector);
    }
  }

  async uploadImage(UploadURL, imageFile) {
    try {
      // Convert and resize the image to a JPEG blob
      const compressedFile = await convertAndResizeImage(imageFile);

      // Options for the image compression
      const options = {
      maxSizeMB: 5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      };

      // Compress the JPEG blob if necessary
      // const compressedFile = await imageCompression(jpegBlob, options);
      // Ensure you have the imageCompression function available and properly imported
      // Compress the JPEG blob
  
      // Since the compressedFile is already a blob, you can directly use it for upload
      return axios.put(UploadURL, compressedFile, {
        headers: { 'Content-Type': compressedFile.type },
      });
      
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }

  async completeAccount(formData) {
    const headers = await authHeader();
    return axiosInstance.post('auth/signup/?verification_complete=true', formData, {
      headers: headers 
    });
  }

  async getProfile(profile_id = 'me'){
    const headers = await authHeader();
    return axiosInstance.get(`profiles/${profile_id}/retrieve`,{
      headers: headers 
    });
  }

  async deleteAccount(){
    const headers = await authHeader();
    return axiosInstance.delete('profiles/destroy', {
      headers: headers  
    });
  }



  async searchArtist(artist) {
    const headers = await authHeader();    
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return axiosInstance.post('recommender/search_paginated/artist/first_page', 
      {
        "search_query": artist,
        "user_action_timestamp": currentTimestamp
      },
      {
        headers: headers 
      }
    );
  }

  async searchProfiles(artist) {
    const headers = await authHeader();    
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return axiosInstance.post('recommender/search_paginated/profiles/first_page', 
      {
        "search_query": artist,
        "user_action_timestamp": currentTimestamp
      },
      {
        headers: headers 
      }
    );
  }

  async searchInstitutions(artist) {
    const headers = await authHeader();    
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return axiosInstance.post('recommender/search_paginated/institutions/first_page', 
      {
        "search_query": artist,
        "user_action_timestamp": currentTimestamp
      },
      {
        headers: headers 
      }
    );
  }

  async getArtworksPaginated(profile_id = null) {
    const session = await supabase.auth.getSession();
    if (session && session?.data?.session?.access_token) {
      if(!profile_id) profile_id = session?.data?.session?.user?.user_metadata?.profile_id
      return axiosInstance.get(`artworks/profile/${profile_id}`)
    }
    return 
  }

  async getVendor() {
    return axiosInstance.get('stripe/register')
  }



}

export default new UserService();