import React, { useRef, useState, createRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import supabase from '../services/supabaseClient';
import { Datepicker } from 'flowbite-react';
import userService from '../services/user.service';
import Navbar from './menus.component';
import CustomButton from './customComponents/buttons'
import _, { ButtonSignup } from './waitinglist.component'
import { useTranslation } from 'react-i18next'
import 'swiper/css';
import 'flowbite';


const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const handleTabKey = (e) => {
    if (e.key === 'Tab') {
        e.preventDefault();
    }
};

function useCountdown(initialState = 60) {
    const [countdown, setCountdown] = useState(initialState);
  
    useEffect(() => {
      let timer;
      if (countdown > 0) {
        timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      }
      return () => clearTimeout(timer);
    }, [countdown]);
  
    return [countdown, setCountdown];
}

export function ProfileImage() {
    const [image, setImage] = useState(null);
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result); // Set image source to the file's data URL
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="custom-field">
            <label htmlFor="profilePicture" className="block text-sm font-medium text-gray-700 text-center">Profile Picture</label>
            <div className="mt-1 flex justify-center items-center">
                {image ? (
                    <div className='d flex flex-col items-center justify-center'>
                        <img 
                            src={image} 
                            alt="Profile" 
                            className="w-24 h-24 rounded-full object-cover" // Adjust these classes as needed
                            onClick={handleButtonClick}
                            style={{ cursor: 'pointer' }}
                        />
                        <label className="block text-sm font-small text-gray-700 text-center mt-5">Change</label>
                    </div>
                ) : (
                    <button 
                        className="w-24 h-24 cursor-pointer rounded-full flex justify-center items-center border border-gray-300"
                        onClick={handleButtonClick}
                        style={{ borderRadius: '100%', color: 'white' }}
                    >
                    Upload
                    </button>
                )}
                <input 
                    id="profilePicture" 
                    type="file" 
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleFileChange} // Handle file selection
                    style={{borderRadius: '100%'}}
                />
            </div>
        </div>
    );
}

export function ButtonOTP({ hasSubmited, onClick }) {

    const buttonStyle = hasSubmited ? 'secondaryButton' : 'primaryButton'
    const buttonText = hasSubmited ? 'Sent OTP' : 'Get Code'

    return (
        <button 
        style={{minWidth: '0px'}}
        className={`${buttonStyle} w-full py-2 rounded-md shadow-sm`} onClick={onClick} disabled={hasSubmited}>
            <span>{buttonText}</span>
        </button>
    )

}

export function ButtonVerifyOTP({hasSubmited, onClick}){
    const buttonStyle = hasSubmited ? 'primaryButton' : 'secondaryButton'
    const buttonText = hasSubmited ?  'Confirm code' : 'Verify email'
    const { t } = useTranslation()

    return (
        <CustomButton className={`${buttonStyle} w-full py-2 rounded-md shadow-sm`} onClick={onClick} disabled={!hasSubmited}
        style={{minWidth: 'auto'}} singleUse={true}>
        <span>{t(buttonText)}</span>
        </CustomButton>
    )
}

export function OTPComponent({ onReady = () => {}, onChange = () => {} }) {
    const numInputs = 6;
    const [inputValues, setInputValues] = useState(Array(numInputs).fill(''));
    const [currentFocusIndex, setCurrentFocusIndex] = useState(null);


    useEffect(() => {
        const allFilled = inputValues.every(value => value !== '');
        if (allFilled) {
            onReady(inputValues.join(''));
        } else {
            onReady(0);
        }
    }, [inputValues]);

    const handleKeyDown = (event) => {
        const { key, target } = event;
        const index = parseInt(target.dataset.index, 10);
        if (key === "Backspace") {
            let newInputValues = [...inputValues];
            newInputValues[index] = '';
            setInputValues(newInputValues);

            if (index > 0 && !target.value) {
                setCurrentFocusIndex(index - 1);
            }
        }
    };

    const handleChange = (event) => {
        const { value, dataset } = event.target;
        const index = parseInt(dataset.index, 10);
        let newInputValues = [...inputValues];

        if (value && /^[0-9]$/.test(value)) {
            newInputValues[index] = value;
            setInputValues(newInputValues);

            const nextIndex = index + 1 < numInputs ? index + 1 : index;
            setCurrentFocusIndex(nextIndex); // Move to next input or stay on the last one
        } else {
            newInputValues[index] = '';
            setInputValues(newInputValues);
        }
        
        onChange(value, index);
    };

    const currentFocus = () => {
        // Focus the input at currentFocusIndex
        document.querySelector(`input[data-index="${currentFocusIndex}"]`)?.focus();
    };

    const handlePaste = (event) => {
        const paste = event.clipboardData.getData('text');
        const numbers = paste.split('').filter(n => /^[0-9]$/.test(n)).slice(0, numInputs);
        if (numbers.length > 0) {
            const index = parseInt(event.target.dataset.index, 10);
            let newInputValues = [...inputValues];
            numbers.forEach((num, i) => {
                if (index + i < numInputs) {
                    newInputValues[index + i] = num;
                }
            });
            setInputValues(newInputValues);
            const nextFocusIndex = index + numbers.length < numInputs ? index + numbers.length : numInputs - 1;
            setCurrentFocusIndex(nextFocusIndex);
        }
        event.preventDefault();
    };

    useEffect(() => {
        currentFocus();
    }, [currentFocusIndex]);

    const inputs = inputValues.map((value, index) => (
        <input
            key={index}
            data-index={index}
            style={{ maxWidth: "50px" }}
            maxLength={1}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onClick={currentFocus}
            onFocus={currentFocus}
            {...(index === 0 ? { onPaste: handlePaste } : {})}
            disabled={index > 0 && !inputValues[index - 1]}
            pattern="[0-9]*" 
            inputMode="numeric"
        />
    ));

    return <div className='custom-field otp'>{inputs}</div>;
}


const LoginPage = () => {
    const swiperRef = useRef(null);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [displayOTP, setDisplayOTP] = useState(false);
    const [countdown, setCountdown] = useCountdown();

    const { t } = useTranslation()
  
    const [state, setState] = useState({
        validEmail: false,
        submittedOTP: false,
        canSubmitOTP: false,

        RegisterResponseMessage: { type: "", text: "" },
        EmailResponseMessage: { type: "", text: "" },
        OTPResponseMessage: { type: "", text: "" },
        ResendOTPMessage: { type: "", text: "" },
    });    

    const S3_VIDEO_URL = 'https://d2w4utxndi3kz9.cloudfront.net/website/output-smallest.mp4'

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    // Consolidated function to navigate between slides
    const navigateToSlide = (index) => swiperRef.current?.swiper?.slideTo(index);
        
    /*

    Email submittion screen OPT

    */

    const handleEmailSubmit = async () => {
        // Assuming `email` is accessible in this context, otherwise, pass it as a parameter
      
        if (!validateEmail(email)) {
          // Directly update state with validation error message
          return {
            success: false,
            message: 'Please enter a valid email address.',
            type: 'text-red-600'
          };
        }
      
        try {
          const { error } = await supabase.auth.signInWithOtp({ 
            email,
            options: {
                shouldCreateUser: true
            }
          });
      
          if (error) {
            
            // Handle API error by returning an error state
            if (error.status === 429) {
                return {
                    success: true, // Treat as success for specific use case
                    message: 'Verification is arleady sent.',
                    type: 'text-yellow-600' // Use a different color to indicate special case
                };
            }

            // Handle other API errors by returning an error state
            return {
                success: false,
                message: error.message,
                type: 'text-red-600'
            };
          }
      
          // Success: Email is valid and OTP request is successful
          // Optionally update state here or in the calling component based on the return value
          return {
            success: true,
            message: 'A verification email has been sent.',
            type: 'text-green-600'
          };
      
        } catch (error) {
          // Handle unexpected errors
          let errorMessage = 'An error occurred, please try again.';
          let errorType = 'text-red-600';
      
          if (error.status === 429) {
            // Too many requests
            return {
                success: true, // Treat as success for specific use case
                message: 'Verification is arleady sent.',
                type: 'text-yellow-600' // Use a different color to indicate special case
            };
          } else {
            console.error("Error during email submission:", error.message);
          }
      
          return {
            success: false,
            message: errorMessage,
            type: errorType
          };
        }
    };
  
    const submitEmail = async () => {
        const result = await handleEmailSubmit();
        setState(prevState => ({
          ...prevState,
          EmailResponseMessage: {
            type: result.type, 
            text: result.message
          }
        }));

        if(result.success)navigateToSlide(1)
        return result.success ? result.success : null
    };

    /*

    OTP Verification Screen 

    */

    const handleOtpSubmit = async () => {
        // Validation check for OTP, assuming `otp` is accessible in this context
        if (!otp || otp.toString().length === 0) {
            return {
                success: false,
                message: 'OTP is required.',
                type: 'text-red-600'
            };
        }
    
        try {
            const { error, data } = await supabase.auth.verifyOtp({ email, token: otp.toString(), type: 'email' });
            if (error) {
                // Handle API error by returning an error state
                return {
                    success: false,
                    message: error.message,
                    type: 'text-red-600'
                };
            }
    
            if (data) {
                // Success: OTP is valid and verification is successful
                return {
                    success: true,
                    message: 'Successfully logged in.',
                    data: data,
                    type: 'text-green-600'
                };
            }
    
            // In case there's no error but also no data, handle as a failure
            return {
                success: false,
                message: 'Verification failed, please try again.',
                type: 'text-red-600'
            };
        } catch (error) {
            // Handle unexpected errors
            console.error("OTP Verification Error:", error.message);
            return {
                success: false,
                message: 'An error occurred during OTP verification, please try again.',
                type: 'text-red-600'
            };
        }
    };

    const submitOtp = async () => {
        const result = await handleOtpSubmit();
        setState(prevState => ({
            ...prevState,
            OTPResponseMessage: {
                type: result.type, 
                text: result.message
            }
        }));

        // Optionally update state here or in the calling component based on the return value
        // Assuming this function processes the account data
        if(result.data) processAccount(supabase, result.data); 

        // Return success status; null if not successful to adhere to your request
        return result.success ? true : null;
    };

    const handleOtpReady = (otpValue) => {
        const otpString = otpValue.toString();
        setState((prev) => ({
            ...prev,
            canSubmitOTP: otpString.length === 6,
        }));
        setOtp(otpString);
    }

    const ResendVerificationEmail = async () => {
        try {
            const { error } = await supabase.auth.signInWithOtp({ email });
            if (error) throw error;
    
            // If the OTP is successfully resent
            setState((prev) => ({
                ...prev,
                OTPResponseMessage: {
                    type: 'text-green-600',
                    text: 'OTP Resent.'
                },
                canSubmitOTP: true
            }));
            setCountdown(60); // Reset the countdown
        } catch (error) {
            console.error("Error resending OTP:", error.message);
            setState((prev) => ({
                ...prev,
                OTPResponseMessage: {
                    type: 'text-red-600',
                    text: error.message,
                },
                canSubmitOTP: false
            }));
        }
    };

    const processAccount = async (supabase, data) => {
        console.log(data?.user?.user_metadata, data?.user)
        if(data?.user?.user_metadata?.profile_id){
            // They have a profile id so take them home
            localStorage.setItem('supabaseClient', JSON.stringify(supabase));
            window.location.href = '/';
        } else{
            // Go the final step on account creation
            localStorage.setItem('supabaseClient', JSON.stringify(supabase));
            navigateToSlide(2);
        }
    }

    /*

    Account Create Screen

    */

    const handleRegisterSubmit = async ({ username, dateOfBirth, profileDescription }) => {
        // Check if username is not provided
        if (!username) {
          return {
            success: false,
            message: 'Username is required.',
            type: 'text-red-600',
          };
        }
      
        // Initialize payload with username and date_of_birth
        const payload = {
          username: username,
          date_of_birth: new Date(dateOfBirth).toISOString(),
        };
      
        // Add profileDescription to payload if it's provided (assuming 'valid' means not empty or null)
        if (profileDescription) {
          payload.profileDescription = profileDescription;
        }
    
        try {
          const { error } = await userService.completeAccount(payload);
      
          if (error) {
            let errorMessage = extractFirstErrorMessage(error);
            let errorType = 'text-red-600';
      
            if (error.status === 429) {
              return {
                success: false,
                message: 'Too many requests, please try again later.',
                type: 'text-yellow-600',
              };
            } else {
              return {
                success: false,
                message: errorMessage,
                type: errorType,
              };
            }
          }
          // Success: Account creation is successful
          return {
            success: true,
            message: 'Account created successfully.',
            type: 'text-green-600',
          };
      
        } catch (error) {
          console.error("Error during account registration:", error.response);
          let errorMessage = extractFirstErrorMessage(error);
          return {
            success: false,
            message: errorMessage,
            type: 'text-red-600',
          };
        }
    };
      
    const submitRegistration = async () => {
        const form = document.getElementById('AccountCreateForm');
        const result = await handleRegisterSubmit({
            username: form.username.value,
            dateOfBirth: form.dateOfBirth.value,
            profileDescription: form.profile_description.value,
        });
        
        setState(prevState => ({
            ...prevState,
            RegisterResponseMessage: {
            type: result.type,
            text: result.message,
            }
        }));

        if (result.success) {
            await supabase.auth.refreshSession();
            window.location.href = '/';
        }
        return result.success;
    };

    /*

    Utils

    */

    const extractFirstErrorMessage = (error) => {
        const responseErrors = error.response.data;
        if (responseErrors && typeof responseErrors === 'object') {
            return responseErrors['message'];
        }

        return error.message || 'An unexpected error occurred.';
    };

    const updateErrorMessageState = (message) => {
        setState((prev) => ({
            ...prev,
            RegisterResponseMessage: {
                type: 'text-red-600',
                text: message,
            },
        }));
    };


    return (
    <div className="login-component relative min-h-screen w-full flex items-center justify-center">
            
        <video autoPlay muted loop playsInline className="absolute top-0 left-0 w-full h-full object-cover" style={{ filter: 'blur(3px)' }}>
            <source src={S3_VIDEO_URL} type="video/mp4" />
            Your browser does not support the video tag.
        </video>

        <Navbar isAuthenticated={false} />

        <div className="overlay-video fixed w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"></div>

        <Swiper ref={swiperRef} spaceBetween={10} slidesPerView={1} allowTouchMove={false} initialSlide={0}>
            <SwiperSlide>
                <div className='introduction-container'>
                    <div className="text-center z-10 w-full max-w-md " style={{width: '90vw'}}>
                        <h1 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl mb-1">
                        {t('Welcome to Artera Upload')}</h1>
                        <p className="text-lg sm:text-xl md:text-2xl">
                        {t('Here you can join the waitlist to become an artist or upload artworks as an existing artist.')}
                        </p>
                        <div className="flex flex-col items-center gap-4 w-full">
                        <div className={`${false ? 'invalid' : ''} custom-field w-full`}>
                            <input
                            id="emailInput"
                            type="email"
                            placeholder={t('Enter your email')}
                            className={`mt-1 block w-full px-3 py-2 border ${!state.validEmail && email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={handleTabKey}
                            />
                            {state.EmailResponseMessage.type && <div className={`mt-2 text-sm ${state.EmailResponseMessage.type}`}>{t(state.EmailResponseMessage.text)}</div>}
                        </div>
                            <CustomButton 
                            className={`primaryButton w-full py-2 rounded-md shadow-sm`} 
                            onClick={submitEmail} 
                            disabled={false}
                            singleUse={true}>
                            <span>{t('Get code')}</span>
                            </CustomButton>
                        </div>
                        <div className='mt-5'>
                            <a href='https://onelink.to/txubcf' target='_blank' className="small-introduction text-lg sm:text-xl md:text-2xl">
                                {t('Download now')}
                            </a>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='introduction-container'>
                    <div className="text-center z-10 w-full max-w-md " style={{width: '90vw'}}>
                        <h1 className="text-lg font-semibold " style={{fontSize: '20px', fontFamily: 'inter', color: 'white'}}>
                            {t('Enter 6-digit code')}
                        </h1>
                        <p>{t('Your code has been sent to')} <span className="font-medium">{email}</span></p>
                        <form className="space-y-6" onSubmit={(e)=>{e.preventDefault()}}>
                            <OTPComponent onReady={handleOtpReady} />                            
                            <ButtonVerifyOTP onClick={submitOtp} hasSubmited={state.canSubmitOTP}/>
                            <p className='pt-10'>{t('Didn’t get the code?')} <span style={{cursor: 'pointer'}} onClick={ResendVerificationEmail}>{t('Resend')}</span></p>
                            {state.OTPResponseMessage.type && <div className={`mt-2 text-sm ${state.OTPResponseMessage.type}`}>
                            {t(state.OTPResponseMessage.text)}
                            </div> }
                        </form>
                        </div>
                    </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='introduction-container'>
                    <div className="text-center z-10 w-full max-w-md" style={{width: '90vw'}}>
                        <h1 className="text-lg font-semibold" style={{fontSize: '20px', fontFamily: 'inter', color: 'white'}}>
                        {t('Create an account')}
                        </h1>
                        <p>
                        {t('Get started with your Artera account')}
                        </p>
                        
                        <form id='AccountCreateForm' className="space-y-6 mt-5" onSubmit={(e)=>{e.preventDefault()}}>
                            <div className='mt-5'>
                            </div>
                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <div className="custom-field flex-1">
                                    <label htmlFor="username" className="block text-sm font-medium text-gray-700 text-left">
                                    {t('Profile handle')}
                                    </label>
                                    <input 
                                        id="username"
                                        name='username'
                                        type="text" 
                                        placeholder={t('Your username')}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        onKeyDown={handleTabKey}
                                    />
                                </div>
                                <div className="custom-field date-field flex-1">
                                    <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700 text-left">
                                    {t('Date of Birth')}
                                    </label>
                                    <Datepicker  
                                    id="dateOfBirth"
                                    name="dateOfBirth"/>
                                </div>
                            </div>
                            <div className="custom-field">
                                <label htmlFor="profileDescription" className="block text-sm font-medium text-gray-700 text-left">
                                {t('Profile Description')}</label>
                                <textarea 
                                    id="profileDescription"
                                    name="profile_description"
                                    placeholder={t("Your description")}
                                    rows="4"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    style={{height: '60px'}}
                                ></textarea>
                            </div>
                            <div className={`mt-2 text-sm ${state.RegisterResponseMessage.type}`}>{t(state.RegisterResponseMessage.text)}</div>
                            <div className='custom-field'>
                                <CustomButton className='primaryButton' onClick={submitRegistration}>
                                    <span>
                                        {t("Create an account")}
                                    </span>
                                </CustomButton>
                            </div>
                        </form>

                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
    );
};

export default LoginPage;
