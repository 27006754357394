import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Introduction from './introduction.component';
import UploadPage from './upload.component';
import DragDropPage from './dragndrop.component';
import DetailsPage from './artwork.component';
import ConfirmationPage from './confirm.component';
import Background from './background.component';
import get_preassigned_urls, { upload_images_to_cdn } from '../utils/manageUploads';
import userService from '../services/user.service';
import Navbar from './menus.component';



const Home = () => {
  const swiperRef = useRef(null);
  const [backgroundOpacityLevel, setBackgroundOpacityLevel] = useState(0.6);
  const [pendingUploadFiles, setPendingUploadFiles] = useState([]);
  const [cdnLinksForArtworks, setCdnLinksForArtworks] = useState([]);
  const [artworkUploadFormData, setArtworkUploadFormData] = useState([]);

  const navigateToSlide = (index) => {
      if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.slideTo(index);
      }
      setBackgroundOpacityLevel(index > 0 ? 0.2 : 1);
  };

  const handleDragDrop = async (images) => {
    let cdnUploadLinks = await get_preassigned_urls(images);
    setCdnLinksForArtworks(cdnUploadLinks);
    upload_images_to_cdn(cdnUploadLinks);
    navigateToSlide(2);
  };

  const handleDetailsSubmit = async (data) => {
    let createdArtworks = await userService.createArtworks(data);
    let cretedVectors = await userService.createRecommendationVector(createdArtworks)
    navigateToSlide(3);
  };

  const onReturn = () => {
    navigateToSlide(1)
  }


  const handleConfirmation = async () => {
  };
  

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);

    const page = urlParams.has('page') ? urlParams.get('page') : '1';
    
    const isNextPage = urlParams.get('v') === 'upload';
    
    if(isNextPage) {
      navigateToSlide(1)
    }
  }, [])

  return (
      <>
        <Navbar />
        <Swiper ref={swiperRef} spaceBetween={50} slidesPerView={1} allowTouchMove={false} initialSlide={0}>
          <SwiperSlide>
            <Introduction onStart={() => navigateToSlide(1)} />
          </SwiperSlide>

          <SwiperSlide>
            <DragDropPage onDragDrop={handleDragDrop}/>
          </SwiperSlide>

          <SwiperSlide>
            <DetailsPage imageArrayPreAssigned={cdnLinksForArtworks} onDetailsSubmit={handleDetailsSubmit} onReturn={onReturn}/>
          </SwiperSlide>

          <SwiperSlide>
            <ConfirmationPage imageArray={pendingUploadFiles} onConfirm={handleConfirmation}/>
          </SwiperSlide>

        </Swiper>
        <Background opacity={backgroundOpacityLevel} className='blur-background' />
      </>
  );
};

export default Home;
