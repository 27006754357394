import React, { useState, useEffect, useRef, Component } from 'react';
import { Button, Input, User, Spacer, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, toggle  } from "@nextui-org/react";
import AudioRecorderComponent from './artwork.audio';
import {Switch, cn} from "@nextui-org/react";
import {RadioGroup, Radio} from "@nextui-org/react";
import ArtistSearchField, { InstitutionalSearchField } from '../customComponents/lookup'
import CustomButton from '../customComponents/buttons';
import { Datepicker } from 'flowbite-react';
import profilePicture from '../../media/profile-picture.svg'
import ModalComponent from '../modules/artwork.modal'
import ToggleSwitch from '../customComponents/toggle'
import { useTranslation } from 'react-i18next'
import { useVendor } from '../../providers/useVendor';

import _ from 'lodash';

const setDateFormat = (dateStr) => {
    
    // Create a new Date object using the provided date string
    let date = new Date()
    if (dateStr) date = new Date(dateStr);

    // Format the date as "YYYY-MM-DD"
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
};
  
const ArtworkUploadForm = ({ initialFormData, profile, onChange, onSubmit, selectedImageIndexProp, imageArray, submitValidation = false,  onReturn =() => {}, onNextItem = ()=> {}, artworksLength = 1 }) => {
    const [formData, setFormData] = useState(initialFormData);
    const debouncedOnChange = useRef(_.debounce(onChange, 300)).current;
    const formRef = useRef(null);
    const { t } = useTranslation()
    // const { vendor } = useVendor()

    useEffect(() => {
        formRef.current.reset();
        const formDataWithDefaults = { ...initialFormData };
        Object.keys(formDataWithDefaults).forEach(key => {
            if (formDataWithDefaults[key] === undefined) {
                formDataWithDefaults[key] = '';
            }            
        });  

        // formDataWithDefaults['location'] = formDataWithDefaults['location'] ? (
        //     formDataWithDefaults['location']
        // ) : (
        //     profile?.profile_type=='institution' ? ( 
        //         profile?.profile_name ||  profile?.profile_handle || ''
        //     ) : ( 
        //         formDataWithDefaults['location']
        //     )
        // )

        setFormData(formDataWithDefaults);
    }, [selectedImageIndexProp, imageArray]);

    const handleSubmit = (event) => {
        event.preventDefault()
    }

    const handleDebouncedChange = (event) => {
        const { name, value } = event.target;
        debouncedOnChange(name, value, selectedImageIndexProp);
    };

    return (
        <form ref={formRef} className="flex flex-col space-y-4 w-full md:w-3/4 lg:w-2/3 xl:w-2/2" onSubmit={handleSubmit}>
            {profile?.profile_type=='institution'  ? (
                <ArtistSearchField 
                onChange={onChange} 
                disbaled={profile?.profile_type=='institution'  ? false : true}
                onReset={selectedImageIndexProp}
                defaultProfile={
                    formData?.artist_profile ? (
                        formData.artist_profile
                    ) : profile?.profile_type=='artist'  ? profile : null
                }
                />
            ) : (null)}

            {profile?.profile_type === 'artist' || profile?.profile_type === 'user' ? (
                <div className="flex items-start">
                    <div className="profile-widget ml-4 hidden md:flex">
                        <User 
                            className='bg-transparent'
                            name={`@${profile?.profile_handle}`}
                            description={profile?.profile_type}
                            avatarProps={{
                                src: profile?.profile_images?.profile_picture?.main_300 || profilePicture
                            }}
                        />
                    </div>
                </div>
            ) : (null)}

            <div className='custom-field'>
                <label htmlFor="title" className="block text-sm font-medium text-white text-left">{t("Artwork Title")}</label>
                <input 
                id="title" 
                placeholder={t("Add artwork title")}
                className="mt-1 block w-full px-3 py-2" 
                name='title'
                maxLength="100"
                defaultValue={formData?.title} 
                key={formData?.title}
                onChange={handleDebouncedChange} />
            </div>

            {profile?.profile_type=='institution' || profile?.profile_type === 'artist' || profile?.profile_type === 'user' ? (
                <InstitutionalSearchField 
                onChange={onChange} 
                disbaled={false}
                defaultProfile={
                formData?.institution_profile ? (
                    formData.institution_profile
                ) : profile?.profile_type=='institution'  ? profile : null}/> 
            ) : (null)}

            <div className='custom-field mr-0 calendar'>
                <label htmlFor="date" className="block text-sm font-medium text-white text-left">{t("Artwork Date")}</label>                        
                <Datepicker        
                id="dateOfBirth"
                name="dateOfBirth"
                value={setDateFormat(initialFormData?.date)}
                onSelectedDateChanged={(date) => {onChange('date', new Date(date).toISOString())}}
                maxDate={new Date()} 
                />
            </div>
  
            <div className='custom-field'>
                <label htmlFor="artwork_description" className="block text-sm font-medium text-white text-left">
                {t("Description")}
                </label>
                <input id="artwork_description" 
                placeholder={t("Add a description" )}
                maxLength="6000"
                className="h-20 mt-1 block w-full px-3 py-2" 
                name='artwork_description'
                defaultValue={formData?.artwork_description?.comment_content || ''} 
                key={formData?.artwork_description?.comment_content || ''}          
                onChange={handleDebouncedChange} />
            </div>
        
            {/* <div className='custom-field'>
                <ModalComponent 
                    onChange={(pricing) => onChange('pricing', pricing)}
                    defaultValue={initialFormData?.pricing || {}}
                    onReset={selectedImageIndexProp}
                />
            </div> */}

            <div className='custom-field-center'>
                <ToggleSwitch options={['Public','Private']} value={initialFormData?.privacy} onChange={(privacy) => onChange('privacy', privacy)} />
            </div>

        <div className="flex flex-row pt-10">

            {artworksLength != 1 ? (
                <div className='w-1/2'>
                    <div className='custom-field'>
                        <button className='primaryButton' onClick={onNextItem}>
                            <span>Next Artwork {selectedImageIndexProp + 1}/{artworksLength}</span>
                        </button>
                    </div>
                </div>
            ) : (null)}
            
            <div className={`${artworksLength != 1 ? 'w-1/2' : 'w-[100%]'}`}>
                <div className='custom-field'>
                    <CustomButton 
                    className='primaryButton' 
                    onClick={onSubmit} 
                    disabled={!submitValidation} 
                    singleUse={true}>
                        <span>{t('Publish Artwork')}</span>
                        {/* <span>{t(`Publish ${artworksLength != 1 ? 'Artworks' : 'Artwork'}`)}</span> */}
                    </CustomButton>
                </div>
            </div>
        </div>

        <div className="flex justify-center"> 
            <a className='custom-field-advanced' onClick={onReturn} style={{margin: 'auto'}}>
                <span>{t('Cancel')}</span>
            </a>
        </div>

    
        </form>
    )
}

export default ArtworkUploadForm